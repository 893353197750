import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Americantrucksimulator = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #a68fec, #9277df, #a68fec, #9277df, #a68fec )",
        icons: "#a68fec",
        navClass: "ats",
      }}
      seo={{
        title: "American Truck Simulator",
        headerTitle: "ats",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/ats_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/ats/",
      }}
    >
      <PortfolioHeader className="img_ats" name="ats" height="720" />
      <section className="container-fluid ats_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>American Truck Simulator</h1>
              <ul>
                <li>Website</li>
                <li className="first">Lead Campaign</li>
                <li>Banner Ads</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner text ">
              <p>
                <strong>Main Goal:</strong> Sale of the planned outlay for the
                first quarter of 2016 <br />
                <strong>Additional Goals:</strong> Building awareness of the
                game in a group of potentially interested players, gathering a
                valuable e-mail database, engaging opinion leaders.
              </p>
              <p>
                We have created a competition application in which top 20 users
                could win collector's edition of the game and ATS posters
                customized with their names on it, along with greetings from the
                well-known Youtubers, and the ATS team.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_3">
        <div className="col-md-4 offset-md-1">
          <div className="inner">
            <p>
              Our posts educated users about the game so that they could then
              answer questions from the contest quiz.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_4">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../../assets/img/portfolio/label_ats.png")}
              alt=""
              className="img-fluid img0"
            />
          </div>
          <div className="col-md-3 offset-md-1 col_z">
            <img
              src={require("../../../assets/img/portfolio/img2_ats.png")}
              alt=""
              className="img-fluid img2"
            ></img>
            <img
              src={require("../../../assets/img/portfolio/super_ats.png")}
              alt=""
              className="img-fluid icon1"
            ></img>
            <img
              src={require("../../../assets/img/portfolio/wow_ats.png")}
              alt=""
              className="img-fluid icon3"
            ></img>
          </div>
          <div className="col-md-3 col_x">
            <img
              src={require("../../../assets/img/portfolio/like_ats.png")}
              alt=""
              className="img-fluid icon2"
            ></img>

            <img
              src={require("../../../assets/img/portfolio/img1_ats.png")}
              alt=""
              className="img-fluid img1"
            ></img>

            <img
              src={require("../../../assets/img/portfolio/img3_ats.png")}
              alt=""
              className="img-fluid img3"
            ></img>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_5">
        <div className="row">
          <div className="col-md-3">
            <img
              src={require("../../../assets/img/portfolio/game_ats.png")}
              alt=""
              className="img-fluid game"
            />
          </div>
          <div className="col-md-3 col_3">
            {" "}
            <img
              src={require("../../../assets/img/portfolio/rules_ats.png")}
              alt=""
              className="img-fluid rules"
            />
          </div>
          <div className="col-md-4 offset-md-1 col_1">
            <div className="inner">
              <p>
                Both the competition and the game pre-order information were
                promoted through the Facebook ADS.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid ats_section_6">
        <div className="row">
          <div className="col-md-5 offset-md-1 col_2">
            <div className="inner">
              <p>
                We have established close cooperation with a well-known Polish
                Youtuber "Wujka Bohuna", who:
              </p>
              <ul>
                <li>promoted the game to his fans</li>
                <li>helped to create quiz questions</li>
                <li>published news regarding the game on his fanpage</li>
                <li>signed posters for players</li>
              </ul>
            </div>
          </div>
          <div className="col-md-5">
            <img
              src={require("../../../assets/img/portfolio/page_ats.png")}
              alt=""
              className="img-fluid page"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid ats_section_7">
        <div className="row">
          <div className="col-md-3">
            <img
              src={require("../../../assets/img/portfolio/yt_ats.png")}
              alt=""
              className="img-fluid yt"
            ></img>
          </div>
          <div className="col-md-3 hide">
            <img
              src={require("../../../assets/img/portfolio/fp_ats.png")}
              alt=""
              className="img-fluid fp"
            ></img>
          </div>

          <div className="col-md-6 offset-md-5">
            <div className="inner">
              <p>Within 2 months our marketing campaign recorded:</p>
              <ul>
                <li>
                  18 million views via the Google AdWords adertising network
                </li>
                <li>
                  A significant increase in the number of fans on the World of
                  Simulators fanpage
                </li>
                <li>
                  The entire volume of the game planned for the first quarter
                  was sold
                </li>
                <li>
                  Starting a promotional campaign for a new game add-on directly
                  with the distributor
                </li>
                <li>
                  During the first two weeks of the campaign, the goals planned
                  for the entire 2 months of the campaign were achieved.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Americantrucksimulator;
